"use client";

import ProgramsCarouselLatest from "@/components/ui/programs/ProgramsCarouselLatest";

export default function ProgramsCarouselHome() {
  return (
    <div className="container mx-auto py-16 px-5">
      <div className="text-center">
        <span className="font-light text-3xl">
          ACCEDE A MÁS DE 500 CLASES QUE HEMOS CREADO PARA TI
        </span>
        <h2 className="font-bold text-4xl mt-5">
          DISFRUTA DE NUESTRAS CLASES Y PROGRAMAS
        </h2>
      </div>

      <ProgramsCarouselLatest />
    </div>
  );
}
