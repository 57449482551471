"use client";
import { useEffect, useRef, useState } from "react";

import axiosInstance from "@/utils/axiosInstance";
import Slider from "react-slick";
import ProgramCard from "./ProgramCard";
import { ChevronDownIcon } from "../Icons";

export default function ProgramsCarouselLatest() {
  const [programs, setPrograms] = useState([]);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 5,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current?.slickPrev();
  };

  useEffect(() => {
    const getClasses = async () => {
      try {
        const res = await axiosInstance.get(
          `${process.env.NEXT_PUBLIC_API_URL_BASE}/programs/latest-programs`
        );
        //console.log(res.data);
        setPrograms(res.data);
      } catch (error) {
        //console.log("Error fetching classes", error);
      }
    };

    getClasses();
  }, []);

  return (
    <div className="mt-5 relative">
      <button
        className="absolute top-3 -left-5 bg-gradient-to-r from-20 from-black to-black/0 h-[180px] w-16 z-10 flex items-center justify-center opacity-50 hover:opacity-100"
        onClick={handlePrev}
      >
        <ChevronDownIcon className="w-5 h-5 fill-cream rotate-90" />
      </button>
      <Slider {...settings} ref={sliderRef}>
        {programs.map((programItem: any) => (
          <ProgramCard key={programItem.id} program={programItem} />
        ))}
      </Slider>
      <button
        className="absolute top-3 -right-5 bg-gradient-to-l from-20 from-black to-black/0 h-[180px] w-16 z-10 flex items-center justify-center opacity-50 hover:opacity-100 "
        onClick={handleNext}
      >
        <ChevronDownIcon className="w-5 h-5 fill-cream -rotate-90" />
      </button>
    </div>
  );
}
