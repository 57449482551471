"use client";
/* eslint-disable react-hooks/exhaustive-deps */

import { FavoriteIcon, InfoIcon } from "../Icons";

import { useFavoritesContext } from "@/providers/FavoritesProvider";
import { useRouter } from "next/navigation";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { LevelSmall } from "../classes/ClassLevels";

export default function ProgramCard({ program }: { program: any }) {
  const router = useRouter();
  const pathname = usePathname();
  const { handleFavorite, favorites } = useFavoritesContext();

  const url = new URL(`${process.env.NEXT_PUBLIC_URL_BASE}${pathname}`);
  url.searchParams.set("modal", "true");
  url.searchParams.set("type", "program");
  url.searchParams.set("id", program.id);

  const isFavorite = favorites.programs.includes(
    program ? Number(program.id) : 0
  );

  return (
    <div className="m-3">
      <div
        className={`w-[300px] h-[180px] relative bg-center cursor-pointer rounded-lg hover:scale-105 transition-all duration-300 ease-in-out`}
        style={{
          backgroundSize: "125% 125%",
          backgroundImage: `url("${program.thumbnail_url}")`,
          transition: "background-image 1s",
        }}
        onClick={(event) => {
          event.stopPropagation();
          if ((event.target as Element).closest("button")) {
            event.preventDefault();
          }
          router.push(`${url.toString()}`);
        }}
      >
        <div className="w-[300px] h-[180px] bg-gradient-to-t from-black/60 to-black/10 absolute rounded-lg" />

        <div className="absolute z-10 h-full w-full flex flex-col justify-between p-3">
          <LevelSmall
            level={program.levels.name}
            bg={
              program.levels.name.toLowerCase() == "principiante"
                ? "bg-light-pink"
                : program.levels.name.toLowerCase() == "intermedio"
                ? "bg-orange"
                : "bg-bright-pink"
            }
          />
          <div className="text-base font-medium uppercase text-cream flex flex-row justify-between">
            {program.name}
            <div className="flex gap-3">
              <Link
                href={url.toString()}
                scroll={false}
                className="flex items-center"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <InfoIcon
                  height="24px"
                  className="fill-cream hover:scale-110"
                />
              </Link>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleFavorite("programs", program ? Number(program.id) : 0);
                }}
              >
                <FavoriteIcon
                  height="24px"
                  className={` hover:scale-110 ${
                    isFavorite
                      ? "fill-bright-pink stroke-bright-pink stroke-2"
                      : "stroke-cream fill-transparent stroke-2"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
