"use client";
import ProductCard from "@/components/ui/ecommerce/product/ProductCard";
import ProductCarouselSkeleton from "@/components/ui/skeleton/ProductsCarouselSkeleton";
import Slider from "react-slick";

interface Product {
  id: string;
  name: string;
  price: number;
  thumbnail_url: string;
  slug: string;
  // Add other product properties here
}

interface ProductGridProps {
  products: Product[] | null;
}

export default function ProductCarousel({ products }: ProductGridProps) {
  if (products === null) {
    return <ProductCarouselSkeleton />;
  }

  if (products.length === 0) {
    return (
      <p className="text-center text-gray-600">
        No hay productos disponibles en este momento.
      </p>
    );
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {products.map((product) => (
          <div key={product.id} className="p-3">
            <ProductCard product={product} />
          </div>
        ))}
      </Slider>
    </>
  );
}
