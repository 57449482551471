export const LevelSmall = (props: any) => {
  const { level, bg } = props;
  return (
    <div className={`${bg} w-fit px-3 py-1 rounded-full`}>
      <div className="text-cream text-xs font-medium uppercase">{level}</div>
    </div>
  );
};

export const LevelLarge = (props: any) => {
  const { level, bg } = props;
  return (
    <div className={`${bg} w-fit px-5 py-1 rounded-full`}>
      <div className="text-cream text-xs md:text-base font-medium uppercase">
        {level}
      </div>
    </div>
  );
};
