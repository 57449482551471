"use client";
import Slider from "react-slick";

export default function ProductCarouselSkeleton() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-8 mb-8 text-center container mx-auto py-16">
      <div className="my-5">
        <h3 className="text-3xl font-bold">ELLAS HAN VIVIDO EL CAMBIO</h3>
        <h4 className="font-light text-xl mt-5">
          MUJERES COMO TÚ COMPARTIENDO SU VIAJE DE TRANSFORMACIÓN <br /> CON
          MALU PEREZ ONLINE
        </h4>
      </div>
      <Slider {...settings}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className="border border-gray-200 rounded-md p-4">
            <div className="w-full h-48 bg-gray-200 animate-pulse"></div>
            <div className="text-center mt-4">
              <div className="w-1/2 h-4 bg-gray-200 animate-pulse mx-auto"></div>
              <div className="w-1/4 h-4 bg-gray-200 animate-pulse mx-auto mt-2"></div>
            </div>
            <div className="mt-4">
              <div className="w-1/2 h-8 bg-gray-200 animate-pulse mx-auto"></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
